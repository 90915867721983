import RegisterSaga from "./sagas";
import RegisterReducer from "./reducers";
import registerActions from "./actions";

export const registerReducer = {
	registerReducer: RegisterReducer,
};

export const registerSaga = [RegisterSaga()];

export { registerActions };
