import LandingPageSaga from "./sagas";
import LandingPageReducer from "./reducers";
import LandingPageActions from "./actions";

export const landingpageReducer = {
	landingpageReducer: LandingPageReducer,
};

export const landingpageSaga = [LandingPageSaga()];

export { LandingPageActions };
