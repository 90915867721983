import { Grid } from "@mui/material";
import { GridProps } from "./interface/Grid.interface";

const MGrid = ({ children, ...rest }: GridProps): JSX.Element => {
	return (
		<Grid
			// columns={columns}
			// columnSpacing={columnSpacing}
			// container={container}
			// spacing={spacing}
			{...rest}
		>
			{children}
		</Grid>
	);
};

export default MGrid;
