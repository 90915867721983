import { useState } from "react";
import { MBox } from "components";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { MainLayoutProps } from "./interface/MainLayout.interface";
import { MainLayoutWrapper } from "./index.style";

const MainLayout = ({ children }: MainLayoutProps): JSX.Element => {
	const [isSidebarOpen, setSidebarOpen] = useState(true);

	return (
		<>
			<MainLayoutWrapper>
				<MBox
					sx={{
						display: "flex",
						flex: "1 1 auto",
						flexDirection: "column",
						width: "100%",
					}}
				>
					{children}
				</MBox>
			</MainLayoutWrapper>
			<Navbar onSidebarOpen={() => setSidebarOpen(true)} />
			<Sidebar
				onClose={() => setSidebarOpen(false)}
				open={isSidebarOpen}
			/>
		</>
	);
};

export default MainLayout;
