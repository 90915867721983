import React from "react";
import { PublicRoutes } from "./interface/PublicRoutes.interface";

const publicRoutes: Array<PublicRoutes> = [
	{
		path: "/login",
		Component: React.lazy(() => import("pages/login")),
	},
	{
		path: "/register",
		Component: React.lazy(() => import("pages/register")),
	},
	{
		path: "/basicInfo",
		Component: React.lazy(() => import("pages/register/basicInfo")),
	},
	{
		path: "/forgetPassword",
		Component: React.lazy(() => import("pages/register/forgetPassword")),
	},
	{
		path: "/forgetPassword/change",
		Component: React.lazy(
			() => import("pages/register/forgetPassword-change")
		),
	},
	{
		path: "/testimonial",
		Component: React.lazy(() => import("pages/landingPage")),
	},
	{
		path: "/testimonial/form",
		Component: React.lazy(() => import("pages/testimonialForm")),
	},
	{
		path: "/testimonial/video",
		Component: React.lazy(() => import("pages/testimonialVideo")),
	},
	{
		path: "/testimonial/thankyou",
		Component: React.lazy(() => import("pages/thankyou")),
	},
	{
		path: "/embed",
		Component: React.lazy(() => import("pages/embed")),
	},
	{
		path: "*",
		Component: React.lazy(() => import("pages/login")),
	},
];

export default publicRoutes;
