import { Suspense, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import { PermittedRoutes, publicRoutes, restrictedMainRoutes } from "routes";
import { PublicRoutes, RestrictedRoutes } from "routes/index.interface";
import { MBackdrop, MSnackBar } from "components";
import MainLayout from "layout/mainLayout";
import { userActions } from "pages/login/store/index";
import { useDispatch } from "react-redux";
import { hasTokenExpired } from "utils/Helpers";
import { useSelector } from "react-redux";
import { IRootState } from "store/interface/Store.interface";
import actions from "pages/login/store/actions";

function App() {
	const loading: boolean = useSelector<IRootState, boolean>(
		({ userReducer }) => userReducer.loading
	);
	const token: string | null = localStorage.getItem("token");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isTokenExpired = hasTokenExpired(token);

	useEffect(() => {
		if (token && !isTokenExpired) {
			new Promise((resolve, reject) => {
				dispatch(actions.fetchCurrentUser(resolve, reject));
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	if (isTokenExpired) {
		MSnackBar("Session Expired!", "error", 3000);
		dispatch(userActions.logout());
		localStorage.removeItem("token");
		navigate("/login", { replace: true });
	}

	if (!token || isTokenExpired) {
		return (
			<Routes>
				{publicRoutes.map(({ path, Component }: PublicRoutes) => {
					return (
						<Route
							key={path}
							path={path}
							element={
								<Suspense
									fallback={
										<MBackdrop open={true} size={100} />
									}
								>
									<Component />
								</Suspense>
							}
						/>
					);
				})}
			</Routes>
		);
	}

	if (loading) {
		return <MBackdrop open />;
	}

	return (
		<MainLayout>
			<Routes>
				{restrictedMainRoutes.map(
					({ path, permission, Component }: RestrictedRoutes) => {
						return (
							<Route
								key={path}
								path={path}
								element={
									<PermittedRoutes
										permission={permission}
										Component={Component}
									/>
								}
							/>
						);
					}
				)}
			</Routes>
		</MainLayout>
	);
}

export default App;
