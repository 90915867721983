import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS({
	testimonialGroups: [],
});

export interface Action {
	type: string;
	payload?: any;
	event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case actions.FETCH_TESTIMONIALGROUPS_SUCCESS:
			return state.setIn(["testimonialGroups"], action.payload);

		case actions.SAVE_TESTIMONIALGROUP_SUCCESS:
			let testimonialGroupIndex = 0;

			if (action.event === "ADD") {
				testimonialGroupIndex = state.toJS().testimonialGroups.length;
			}

			if (action.event === "EDIT") {
				testimonialGroupIndex = state
					.toJS()
					.testimonialGroups.findIndex(
						(testimonialGroup: any) =>
							testimonialGroup.id === action.payload.id
					);
			}

			return state.setIn(
				["testimonialGroups", testimonialGroupIndex],
				action.payload
			);

		case actions.DELETE_TESTIMONIALGROUP_SUCCESS:
			const deleteIndex = state
				.toJS()
				.testimonialGroups.findIndex(
					(testimonialGroups: any) =>
						testimonialGroups.id === action.id
				);
			return state.deleteIn(["testimonialGroups", deleteIndex]);
		case actions.FETCH_TAGS_SUCCESS:
			return state.setIn(["tags"], action.payload);

		default:
			return state;
	}
};

export default reducer;
