import { TestimonialGroups } from "../interface/TestimonialGroups.interface";

const actions = {
	FETCH_TESTIMONIALGROUPS: "FETCH_TESTIMONIALGROUPS",
	FETCH_TESTIMONIALGROUPS_SUCCESS: "FETCH_TESTIMONIALGROUPS_SUCCESS",
	FETCH_ONE_TESTIMONIALGROUP: "FETCH_ONE_TESTIMONIALGROUP",
	SAVE_TESTIMONIALGROUP: "SAVE_TESTIMONIALGROUP",
	SAVE_TESTIMONIALGROUP_SUCCESS: "SAVE_TESTIMONIALGROUP_SUCCESS",
	DELETE_TESTIMONIALGROUP: "DELETE_TESTIMONIALGROUP",
	DELETE_TESTIMONIALGROUP_SUCCESS: "DELETE_TESTIMONIALGROUP_SUCCESS",
	FETCH_TAGS: "FETCH_TAGS",
	FETCH_TAGS_SUCCESS: "FETCH_TAGS_SUCCESS",

	fetchTestimonialGroups: (
		resolve: (token: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_TESTIMONIALGROUPS,
		resolve,
		reject,
	}),

	fetchOneTestimonialGroup: (
		id: number | "" | undefined,
		resolve: (value: TestimonialGroups) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_ONE_TESTIMONIALGROUP,
		id,
		resolve,
		reject,
	}),

	saveTestimonialGroup: (
		data: TestimonialGroups,
		event: string,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.SAVE_TESTIMONIALGROUP,
		data,
		event,
		resolve,
		reject,
	}),
	deleteTestimonialGroup: (
		id: number | "" | undefined,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.DELETE_TESTIMONIALGROUP,
		id,
		resolve,
		reject,
	}),

	fetchTags: (
		resolve: (response: any) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_TAGS,
		resolve,
		reject,
	}),
};

export default actions;
