const actions = {
	FETCH_TESTIMONIAL_INSIGHTS: "FETCH_TESTIMONIAL_INSIGHTS",
	FETCH_TESTIMONIAL_INSIGHTS_SUCCESS: "FETCH_TESTIMONIAL_INSIGHTS_SUCCESS",
	FETCH_TESTIMONIAL_INSIGHTS_FAILED: "FETCH_TESTIMONIAL_INSIGHTS_FAILED",
	FETCH_CUSTOMER_ACTIVITY: "FETCH_CUSTOMER_ACTIVITY",
	FETCH_CUSTOMER_ACTIVITY_SUCCESS: "FETCH_CUSTOMER_ACTIVITY_SUCCESS",
	FETCH_CUSTOMER_ACTIVITY_FAILED: "FETCH_CUSTOMER_ACTIVITY_FAILED",
	FETCH_LATEST_TESTIMONIALS: "FETCH_LATEST_TESTIMONIALS",
	FETCH_LATEST_TESTIMONIALS_SUCCESS: "FETCH_LATEST_TESTIMONIALS_SUCCESS",
	FETCH_LATEST_TESTIMONIALS_FAILED: "FETCH_LATEST_TESTIMONIALS_FAILED",
	FETCH_DASHOARD_ONBOARD: "FETCH_DASHOARD_ONBOARD",
	FETCH_DASHOARD_ONBOARD_SUCCESS: "FETCH_DASHOARD_ONBOARD_SUCCESS",
	FETCH_DASHOARD_ONBOARD_FAILED: "FETCH_DASHOARD_ONBOARD_FAILED",

	fetchTestimonialInsights: (
		resolve: (token: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_TESTIMONIAL_INSIGHTS,
		resolve,
		reject,
	}),

	fetchCustomerActivity: (
		resolve: (token: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_CUSTOMER_ACTIVITY,
		resolve,
		reject,
	}),

	fetchLatestTestimonials: (
		count: number,
		resolve: (token: string) => void,
		reject: (value: string) => void
	) => ({
		count: count,
		type: actions.FETCH_LATEST_TESTIMONIALS,
		resolve,
		reject,
	}),

	fetchDashboardOnboard: (
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_DASHOARD_ONBOARD,
		resolve,
		reject,
	}),
};

export default actions;
