const actions = {
	LOGIN: "LOGIN",
	STORE_CURRENT_USER: "STORE_CURRENT_USER",
	LOGIN_FAILED: "LOGIN_FAILED",
	FETCH_CURRENT_USER: "FETCH_CURRENT_USER",
	FETCH_CURRENT_USER_SUCCESS: "FETCH_CURRENT_USER_SUCCESS",
	FETCH_CURRENT_USER_FAILED: "FETCH_CURRENT_USER_FAILED",
	LOGOUT: "LOGOUT",
	UPDATE_USER: "UPDATE_USER",
	UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
	UPDATE_USER_FAILED: "UPDATE_USER_FAILED",

	login: (
		data: Record<string, any>,
		resolve: (token: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.LOGIN,
		data,
		resolve,
		reject,
	}),

	updateUser: (
		data: Record<string, any>,
		resolve: (token: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.UPDATE_USER,
		data,
		resolve,
		reject,
	}),

	fetchCurrentUser: (
		resolve: (token: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_CURRENT_USER,
		resolve,
		reject,
	}),

	logout: () => ({
		type: actions.LOGOUT,
	}),
};

export default actions;
