import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS({
	testimonials: [],
});

export interface Action {
	type: string;
	payload?: any;
	event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case actions.FETCH_TESTIMONIALS_SUCCESS:
			return state.setIn(["testimonials"], action.payload);

		case actions.EDIT_TESTIMONIAL_SUCCESS:
			let testimonialsIndex = state
				.toJS()
				.testimonials.findIndex(
					(testimonial: any) => testimonial.id === action.payload.id
				);
			return state.setIn(
				["testimonials", testimonialsIndex],
				action.payload
			);

		case actions.DELETE_TESTIMONIAL_SUCCESS:
			const deleteIndex = state
				.toJS()
				.testimonials.findIndex(
					(testimonial: any) => testimonial.id === action.id
				);
			return state.deleteIn(["testimonials", deleteIndex]);

		default:
			return state;
	}
};

export default reducer;
