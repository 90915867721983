import { Box } from "@mui/material";
import { BoxProps } from "./interface/Box.interface";

const MBox = ({ children, sx, ...rest }: BoxProps) => {
	if (!children) {
		return <Box sx={sx} />;
	}

	return (
		<Box sx={sx} {...rest}>
			{children}
		</Box>
	);
};

export default MBox;
