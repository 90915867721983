import VideoTutorialsSaga from "./sagas";
import VideoTutorialsReducer from "./reducers";
import videoTutorialsActions from "./actions";

export const videoTutorialsReducer = {
	videoTutorialsReducer: VideoTutorialsReducer,
};

export const videoTutorialsSaga = [VideoTutorialsSaga()];

export { videoTutorialsActions };
