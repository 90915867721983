import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
const { fromJS } = require("immutable");

const initialState = fromJS({
	testimonials: [],
});

const reducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case actions.SAVE_TESTIMONIAL_SUCCESS:
			return state.setIn(["testimonials", 0], action.payload.data);

		case actions.SAVE_VIDEO_TESTIMONIAL_SUCCESS:
			return state.setIn(["testimonials", 0], action.payload.data);

		default:
			return state;
	}
};

export default reducer;
