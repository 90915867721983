const actions = {
	REGISTER: "REGISTER",
	REGISTER_SUCCESS: "REGISTER_SUCCESS",
	REGISTER_FAILED: "REGISTER_FAILED",
	SETBASICINFO: "SETBASICINFO",
	SETBASICINFO_SUCCESS: "SETBASICINFO_SUCCESS",
	SETBASICINFO_FAILED: "SETBASICINFO_FAILED",
	FORGETPASSWORD: "FORGETPASSWORD",
	FORGETPASSWORD_SUCCESS: "FORGETPASSWORD_SUCCESS",
	FORGETPASSWORD_FAILED: "FORGETPASSWORD_FAILED",
	FORGETPASSWORD_CHANGE: "FORGETPASSWORD_CHANGE",
	FORGETPASSWORD_CHANGE_SUCCESS: "FORGETPASSWORD_CHANGE_SUCCESS",
	FORGETPASSWORD_CHANGE_FAILED: "FORGETPASSWORD_CHANGE_FAILED",
	CHANGEPASSWORD: "CHANGEPASSWORD",
	CHANGEPASSWORD_SUCCESS: "CHANGEPASSWORD_SUCCESS",
	CHANGEPASSWORD_FAILED: "CHANGEPASSWORD_FAILED",
	register: (
		data: Record<string, any>,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.REGISTER,
		data,
		resolve,
		reject,
	}),

	setBasicInfo: (
		data: Record<string, any>,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.SETBASICINFO,
		data,
		resolve,
		reject,
	}),

	forgetPassword: (
		data: Record<string, any>,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FORGETPASSWORD,
		data,
		resolve,
		reject,
	}),

	forgetPasswordChange: (
		data: Record<string, any>,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FORGETPASSWORD_CHANGE,
		data,
		resolve,
		reject,
	}),

	changePassword: (
		data: Record<string, any>,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.CHANGEPASSWORD,
		data,
		resolve,
		reject,
	}),
};

export default actions;
