import { Testimonial } from "../interface/Testimonials.interface";

const actions = {
	FETCH_TESTIMONIALS: "FETCH_TESTIMONIALS",
	FETCH_TESTIMONIALS_SUCCESS: "FETCH_TESTIMONIALS_SUCCESS",
	FETCH_TESTIMONIAL: "FETCH_TESTIMONIAL",
	EDIT_TESTIMONIAL: "EDIT_TESTIMONIAL",
	EDIT_TESTIMONIAL_SUCCESS: "EDIT_TESTIMONIAL_SUCCESS",
	DELETE_TESTIMONIAL: "DELETE_TESTIMONIAL",
	DELETE_TESTIMONIAL_SUCCESS: "DELETE_TESTIMONIAL_SUCCESS",

	fetchTestimonials: (
		resolve: (token: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_TESTIMONIALS,
		resolve,
		reject,
	}),

	fetchTestimonial: (
		id: number | "" | undefined,
		resolve: (value: Testimonial) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_TESTIMONIAL,
		id,
		resolve,
		reject,
	}),

	editTestimonials: (
		data: Testimonial | any,
		id: any,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.EDIT_TESTIMONIAL,
		data,
		id,
		resolve,
		reject,
	}),

	deleteTestimonials: (
		id: number | "" | undefined,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.DELETE_TESTIMONIAL,
		id,
		resolve,
		reject,
	}),
};

export default actions;
