import { IconButton, IconButtonProps } from "@mui/material";

const MIconButton = ({ onClick, children, sx }: IconButtonProps) => {
	return (
		<IconButton onClick={onClick} sx={sx}>
			{children}
		</IconButton>
	);
};

export default MIconButton;
