import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS({
	videoTutorials: [],
	VideoTutorialsPage: {},
});

export interface Action {
	type: string;
	payload?: any;
	event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case actions.SAVE_TUTORIAL_SUCCESS:
			let tutorialIndex = 0;
			tutorialIndex = state.toJS().videoTutorials.length;
			return state.setIn(
				["videoTutorials", tutorialIndex],
				action.payload
			);

		case actions.FETCH_TUTORIALS_SUCCESS:
			return state.setIn(["videoTutorials"], action.payload);

		case actions.FETCH_TUTORIAL_PAGES_FAILED:
			return state.setIn(["VideoTutorialsPage"], {});

		case actions.FETCH_TUTORIAL_PAGES_SUCCESS:
			return state.setIn(["VideoTutorialsPage"], action.payload);

		case actions.EDIT_TUTORIAL_SUCCESS:
			let videoTutorialsIndex = state
				.toJS()
				.videoTutorials.findIndex(
					(videoTutorial: any) =>
						videoTutorial.id === action.payload.id
				);
			return state.setIn(
				["videoTutorials", videoTutorialsIndex],
				action.payload
			);

		case actions.DELETE_TUTORIAL_SUCCESS:
			const deleteIndex = state
				.toJS()
				.videoTutorials.findIndex(
					(videoTutorial: any) => videoTutorial.id === action.id
				);
			return state.deleteIn(["videoTutorials", deleteIndex]);

		default:
			return state;
	}
};

export default reducer;
