import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
} from "@mui/material";
import { Item } from "./interface/Select.interface";

const MSelect = ({
	input,
	required = false,
	disabled = false,
	label,
	type = "text",
	items,
	fullWidth = true,
	meta: { touched, error },
	...rest
}: any): JSX.Element => {
	const isError = (): boolean => {
		if (touched && error) {
			return true;
		}

		return false;
	};

	return (
		<FormControl
			disabled={disabled}
			required={required}
			fullWidth={fullWidth}
			error={isError()}
		>
			<InputLabel>{label}</InputLabel>
			<Select
				labelId={label}
				label={label}
				error={isError()}
				{...input}
				{...rest}
			>
				{items.map((item: Item) => (
					<MenuItem key={item.name} value={item.id}>
						{item.name}
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{touched && error}</FormHelperText>
		</FormControl>
	);
};

export default MSelect;
