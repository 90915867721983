import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS({
	customers: [],
	timezones: [],
});

export interface Action {
	type: string;
	payload?: any;
	event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case actions.FETCH_CUSTOMERS_SUCCESS:
			return state.setIn(["customers"], action.payload);

		case actions.SAVE_CUSTOMER_SUCCESS:
			let customersIndex = 0;

			if (action.event === "ADD") {
				customersIndex = state.toJS().customers.length;
			}

			if (action.event === "EDIT") {
				customersIndex = state
					.toJS()
					.customers.findIndex(
						(customer: any) => customer.id === action.payload.id
					);
			}

			return state.setIn(["customers", customersIndex], action.payload);

		case actions.DELETE_CUSTOMER_SUCCESS:
			const deleteIndex = state
				.toJS()
				.customers.findIndex(
					(customer: any) => customer.id === action.id
				);
			return state.deleteIn(["customers", deleteIndex]);

		case actions.FETCH_TIMEZONES_SUCCESS:
			return state.setIn(["timezones"], action.payload);

		case actions.FETCH_TAGS_SUCCESS:
			return state.setIn(["tags"], action.payload);

		default:
			return state;
	}
};

export default reducer;
