import { InputAdornment, InputAdornmentProps } from "@mui/material";

const MInputAdornment = ({
	position = "start",
	children,
	...rest
}: InputAdornmentProps) => {
	return (
		<InputAdornment position={position} {...rest}>
			{children}
		</InputAdornment>
	);
};
export default MInputAdornment;
