import TestimonialSaga from "./sagas";
import TestimonialReducer from "./reducers";
import testimonialActions from "./actions";

export const testimonialReducer = {
	testimonialReducer: TestimonialReducer,
};

export const testimonialSaga = [TestimonialSaga()];

export { testimonialActions };
