import InputWrapper from "./index.style";
import { InputProps } from "./interface/Input.interface";
import { TextField } from "@mui/material";

const MInput = ({
	input,
	label,
	type = "text",
	meta: { touched, error },
	...custom
}: InputProps): JSX.Element => {
	const isError = (): boolean => {
		if (touched && error) {
			return true;
		}

		return false;
	};

	return (
		<InputWrapper>
			<TextField
				error={isError()}
				label={label}
				type={type}
				helperText={touched && error}
				{...input}
				{...custom}
			/>
		</InputWrapper>
	);
};

export default MInput;
