import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import authActions from "../../login/store/actions";

const api = new Api();

interface ReturnTypes {
	[x: string]: string | number | Array<object> | object;
}

export const register = takeLatest(
	actions.REGISTER,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.post,
				"auth/invite",
				action.data,
				null,
				true
			);
			yield put({ type: actions.REGISTER_SUCCESS });
			yield call(action.resolve, response);
		} catch (error) {
			yield put({ type: actions.REGISTER_FAILED });
			yield call(action.reject, error);
		}
	}
);

export const forgetPassword = takeLatest(
	actions.FORGETPASSWORD,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.post,
				"auth/forget-password",
				{
					email: action.data.email,
				},
				null,
				true
			);
			yield put({ type: actions.FORGETPASSWORD_SUCCESS });
			yield call(action.resolve, response);
		} catch (error) {
			yield put({ type: actions.FORGETPASSWORD_FAILED });
			yield call(action.reject, error);
		}
	}
);

export const setBasicInfo = takeLatest(
	actions.SETBASICINFO,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.post,
				"auth/register",
				{
					first_name: action.data.first_name,
					last_name: action.data.last_name,
					email: action.data.email,
					password: action.data.password,
					company_name: action.data.company_name,
					contact_name: action.data.contact_name,
					contact_email: action.data.contact_email,
				},
				null,
				true
			);
			yield put({ type: actions.SETBASICINFO_SUCCESS });
			yield put({
				type: authActions.STORE_CURRENT_USER,
				payload: response.access_token,
			});
			yield call(action.resolve, response.access_token);
		} catch (error) {
			yield put({ type: actions.SETBASICINFO_FAILED });
			yield call(action.reject, "reject");
		}
	}
);

export const forgetPasswordChange = takeLatest(
	actions.FORGETPASSWORD_CHANGE,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.patch,
				"auth/forget-password-change",
				{
					email: action.data.email,
					password: action.data.password,
				},
				null,
				true
			);
			yield put({ type: actions.FORGETPASSWORD_CHANGE_SUCCESS });
			yield call(action.resolve, response.access_token);
		} catch (error) {
			yield put({ type: actions.FORGETPASSWORD_CHANGE_FAILED });
			yield call(action.reject, "reject");
		}
	}
);

// changePassword
export const changePassword = takeLatest(
	actions.CHANGEPASSWORD,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.patch,
				`users/change-password/${action.data.id}`,
				{
					old_password: action.data.old_password,
					new_password: action.data.new_password,
				},
				null
			);
			yield put({ type: actions.CHANGEPASSWORD_SUCCESS });
			yield call(action.resolve, response);
		} catch (error) {
			yield put({ type: actions.CHANGEPASSWORD_FAILED });
			yield call(action.reject, error);
		}
	}
);

export default function* saga() {
	yield all([
		register,
		setBasicInfo,
		forgetPassword,
		forgetPasswordChange,
		changePassword,
	]);
}
