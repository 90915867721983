import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
const { fromJS } = require("immutable");

const initialState = fromJS({
	landingpageTemplates: [],
	tokens: [],
});

const reducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case actions.FETCH_LANDINGPAGE_TEMPLATES_SUCCESS:
			return state.setIn(["landingpageTemplates"], action.payload);

		case actions.SAVE_LANDINGPAGE_TEMPLATE_SUCCESS:
			const landingpageTemplateIndex = state
				.toJS()
				.landingpageTemplates.findIndex(
					(landingpageTemplate: any) =>
						landingpageTemplate.id === action.payload.id
				);
			return state.setIn(
				["landingpageTemplates", landingpageTemplateIndex],
				action.payload
			);

		case actions.FETCH_TOKENS_SUCCESS:
			return state.setIn(["tokens"], action.payload);

		default:
			return state;
	}
};

export default reducer;
