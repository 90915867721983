import CustomersSaga from "./sagas";
import CustomersReducer from "./reducers";
import customersActions from "./actions";

export const customersReducer = {
	customersReducer: CustomersReducer,
};

export const customersSaga = [CustomersSaga()];

export { customersActions };
