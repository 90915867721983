import actions from "./actions";
const { fromJS } = require("immutable");

const initialState = fromJS({
	loading: false,
	latestTestimonials: [],
	customerActivity: [],
	testimonialInsights: [],
	videoTutorials: [],
});

const reducer = (state = initialState, action: Record<string, string>) => {
	switch (action.type) {
		case actions.FETCH_TESTIMONIAL_INSIGHTS:
			return state.setIn(["loading"], true);

		case actions.FETCH_TESTIMONIAL_INSIGHTS_SUCCESS:
			const testimonialInsights = action.payload;
			return state.merge({
				loading: false,
				testimonialInsights: testimonialInsights,
			});

		case actions.FETCH_DASHOARD_ONBOARD:
			return state.setIn(["loading"], true);

		case actions.FETCH_DASHOARD_ONBOARD_SUCCESS:
			const videoTutorials = action.payload;
			return state.merge({
				loading: false,
				videoTutorials: videoTutorials,
			});

		case actions.FETCH_CUSTOMER_ACTIVITY:
			return state.setIn(["loading"], true);

		case actions.FETCH_CUSTOMER_ACTIVITY_SUCCESS:
			const customerActivity = action.payload;
			return state.merge({
				loading: false,
				customerActivity: customerActivity,
			});

		case actions.FETCH_LATEST_TESTIMONIALS:
			return state.setIn(["loading"], true);

		case actions.FETCH_LATEST_TESTIMONIALS_SUCCESS:
			const latestTestimonials = action.payload;
			return state.merge({
				loading: false,
				latestTestimonials: latestTestimonials,
			});

		default:
			return state;
	}
};

export default reducer;
