import { Autocomplete, TextField } from "@mui/material";
import { AutoselectProps } from "./interface/Autoselect.interface";

const MAutoSelect = ({
	input,
	label,
	meta: { touched, error, initial },
	options = [],
	...custom
}: AutoselectProps) => {
	const isError = (): boolean => {
		if (touched && error) {
			return true;
		}
		return false;
	};
	return (
		<Autocomplete
			freeSolo
			id="tags-outlined"
			options={options}
			defaultValue={initial}
			getOptionLabel={option => option.name}
			onChange={(_, value) => {
				input.onChange(value);
			}}
			renderInput={params => {
				return (
					<TextField
						{...params}
						error={isError()}
						label={label}
						helperText={touched && error}
						{...input}
						{...custom}
					/>
				);
			}}
		/>
	);
};

export default MAutoSelect;
