import { ListItem, ListItemProps } from "@mui/material";

const MListItem = ({
	disableGutters = true,
	sx,
	children,
	...rest
}: ListItemProps) => {
	return (
		<ListItem disableGutters={disableGutters} sx={sx} {...rest}>
			{children}
		</ListItem>
	);
};

export default MListItem;
