import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "store";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "theme";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	// <React.StrictMode>
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<BrowserRouter>
				<SnackbarProvider maxSnack={10}>
					<App />
				</SnackbarProvider>
			</BrowserRouter>
		</ThemeProvider>
	</Provider>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
