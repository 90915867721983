import React from "react";
import { RestrictedRoutes } from "./interface/RestrictedRoutes.interface";

const restrictedMainRoutes: Array<RestrictedRoutes> = [
	{
		path: "/",
		Component: React.lazy(() => import("pages/dashboard")),
		permission: [
			"space:create",
			"space:retrieve",
			"space:update",
			"space:list",
			"space:delete",
			"role:create",
			"role:retrieve",
			"role:update",
			"role:list",
		],
	},
	{
		path: "/customers",
		Component: React.lazy(() => import("pages/customers")),
		permission: ["customer:list"],
	},
	{
		path: "/customers/add",
		Component: React.lazy(() => import("pages/customers/add")),
		permission: ["customer:create"],
	},
	{
		path: "/customers/detail/:id",
		Component: React.lazy(() => import("pages/customers/detail")),
		permission: ["customer:retrieve"],
	},
	{
		path: "/customers/edit/:id",
		Component: React.lazy(() => import("pages/customers/edit")),
		permission: ["customer:update"],
	},
	{
		path: "/testimonialGroups",
		Component: React.lazy(() => import("pages/testimonialGroups")),
		permission: ["testimonial_group:list"],
	},
	{
		path: "/testimonialGroups/detail/:id",
		Component: React.lazy(() => import("pages/testimonialGroups/detail")),
		permission: ["testimonial_group:retrieve"],
	},
	{
		path: "/testimonialGroups/add",
		Component: React.lazy(() => import("pages/testimonialGroups/add")),
		permission: ["testimonial_group:create"],
	},
	{
		path: "/testimonialGroups/edit/:id",
		Component: React.lazy(() => import("pages/testimonialGroups/edit")),
		permission: ["testimonial_group:update"],
	},

	{
		path: "/testimonials",
		Component: React.lazy(() => import("pages/testimonials")),
		permission: ["testimonial:list"],
	},
	{
		path: "/testimonials/detail/:id",
		Component: React.lazy(() => import("pages/testimonials/detail")),
		permission: ["testimonial:retrieve"],
	},
	{
		path: "/testimonials/edit/:id",
		Component: React.lazy(() => import("pages/testimonials/edit")),
		permission: ["testimonial:update"],
	},
	{
		path: "/profile",
		Component: React.lazy(() => import("pages/profile")),
		permission: [
			"user:create",
			"user:retrieve",
			"user:update",
			"user:list",
			"user:delete",
			"user:create",
			"user:retrieve",
			"user:update",
			"user:list",
			"user:delete",
		],
	},
	{
		path: "/settings/*",
		Component: React.lazy(() => import("layout/settingsLayout")),
		permission: ["customer:update"],
	},
	{
		path: "/video-tutorials/",
		Component: React.lazy(() => import("pages/videoTutorial")),
		permission: ["video_tutorial:list"],
	},
	{
		path: "/video-tutorials/add",
		Component: React.lazy(() => import("pages/videoTutorial/add/index")),
		permission: ["video_tutorial:create"],
	},
	{
		path: "/video-tutorials/detail/:id",
		Component: React.lazy(() => import("pages/videoTutorial/detail/index")),
		permission: ["video_tutorial:retrieve"],
	},
	{
		path: "/video-tutorials/edit/:id",
		Component: React.lazy(() => import("pages/videoTutorial/edit/index")),
		permission: ["video_tutorial:update"],
	},
	{
		path: "*",
		Component: React.lazy(() => import("pages/notFound")),
		permission: ["*"],
	},
];

const restrictedSettingsRoutes = [
	{
		path: "/account",
		Component: React.lazy(() => import("pages/settings/account")),
		permission: ["*"],
	},
	{
		path: "/emailTemplate",
		Component: React.lazy(() => import("pages/settings/email")),
		permission: ["customer:update"],
	},
	{
		path: "/landingpage",
		Component: React.lazy(() => import("pages/settings/landingPage")),
		permission: ["customer:update"],
	},
];

export { restrictedMainRoutes, restrictedSettingsRoutes };
