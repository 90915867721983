
const actions = {
	SAVE_TESTIMONIAL: "SAVE_TESTIMONIAL",
	SAVE_TESTIMONIAL_SUCCESS: "SAVE_TESTIMONIAL_SUCCESS",
	SAVE_VIDEO_TESTIMONIAL: "SAVE_VIDEO_TESTIMONIAL",
	SAVE_VIDEO_TESTIMONIAL_SUCCESS: "SAVE_VIDEO_TESTIMONIAL_SUCCESS",

	saveTestimonial: (
		data: any,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.SAVE_TESTIMONIAL,
		data,
		resolve,
		reject,
	}),

	saveVideoTestimonial: (
		data: any,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.SAVE_VIDEO_TESTIMONIAL,
		data,
		resolve,
		reject,
	}),
};

export default actions;
