import { Backdrop, CircularProgress } from "@mui/material";
import { BackdropProps } from "./interface/Backdrop.interface";

const MBackdrop = ({ open = false, size = 40, sx }: BackdropProps) => {
	return (
		<Backdrop open={open} sx={sx}>
			<CircularProgress size={size} />
		</Backdrop>
	);
};

export default MBackdrop;
