import { Button } from "@mui/material";
import { ButtonProps } from "./interface/Button.interface";

const MButton = ({
	type = "button",
	children,
	onClick,
	...rest
}: ButtonProps): JSX.Element => {
	return (
		<Button type={type} onClick={onClick} {...rest}>
			{children}
		</Button>
	);
};

export default MButton;
