import { styled } from "@mui/material/styles";
import { AppBar } from "@mui/material";

const MainLayoutWrapper = styled("div")(({ theme }) => ({
	display: "flex",
	flex: "1 1 auto",
	maxWidth: "100%",
	paddingTop: 64,
	[theme.breakpoints.up("lg")]: {
		paddingLeft: 280,
	},
}));

const NavbarWrapper = styled(AppBar)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	boxShadow: theme.shadows[3],
}));

export { MainLayoutWrapper, NavbarWrapper };
