import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";

const api = new Api();

interface ReturnTypes {
	[x: string]: string | number | Array<object> | object;
}

const fetchTestimonialGroups = takeLatest(
	actions.FETCH_TESTIMONIALGROUPS,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(api.get, "/testimonial-group");
			yield put({
				type: actions.FETCH_TESTIMONIALGROUPS_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const fetchOneTestimonialGroup = takeLatest(
	actions.FETCH_ONE_TESTIMONIALGROUP,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.get,
				`/testimonial-group/${action.id}`
			);
			yield call(action.resolve, response);
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const saveTestimonialGroup = takeLatest(
	actions.SAVE_TESTIMONIALGROUP,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			if (action.event === "ADD") {
				const response = yield call(
					api.post,
					"/testimonial-group",
					action.data,
					null
				);
				action.data.id = response.id;
				action.data.embed = response.embed;
				yield put({
					type: actions.SAVE_TESTIMONIALGROUP_SUCCESS,
					payload: action.data,
					event: action.event,
				});
			}

			if (action.event === "EDIT") {
				yield call(
					api.patch,
					`/testimonial-group/${action.data.id}`,
					action.data
				);
				yield put({
					type: actions.SAVE_TESTIMONIALGROUP_SUCCESS,
					payload: action.data,
					event: action.event,
				});
			}
			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const deleteTestimonialGroup = takeLatest(
	actions.DELETE_TESTIMONIALGROUP,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const responseId = yield call(
				api.delete,
				`/testimonial-group/${action.id}`
			);
			yield put({
				type: actions.DELETE_TESTIMONIALGROUP_SUCCESS,
				id: responseId,
			});
			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const fetchTags = takeLatest(
	actions.FETCH_TAGS,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(api.get, "/tags");
			yield put({ type: actions.FETCH_TAGS_SUCCESS, payload: response });
			yield call(action.resolve, response);
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

export default function* saga() {
	yield all([
		fetchTestimonialGroups,
		fetchOneTestimonialGroup,
		saveTestimonialGroup,
		deleteTestimonialGroup,
		fetchTags,
	]);
}
