import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, call, all, put, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import {
	ReturnTypes,
	APIResponseError,
} from "../interface/Testimonial.interface";

const api = new Api();

const saveTestimonial = takeLatest(
	actions.SAVE_TESTIMONIAL,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.post,
				`/testimonial`,
				action.data,
				null,
				true
			);
			yield put({
				type: actions.SAVE_TESTIMONIAL_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, "success");
		} catch (error) {
			const err = error as APIResponseError;
			yield call(action.reject, err.response?.data.message);
		}
	}
);

const saveVideoTestimonial = takeLatest(
	actions.SAVE_VIDEO_TESTIMONIAL,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.post,
				`/testimonial`,
				action.data,
				null,
				true
			);
			yield put({
				type: actions.SAVE_VIDEO_TESTIMONIAL_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, "success");
		} catch (error) {
			const err = error as APIResponseError;
			yield call(action.reject, err.response?.data.message);
		}
	}
);

export default function* saga() {
	yield all([saveTestimonial, saveVideoTestimonial]);
}
