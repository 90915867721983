import { Stack, StackProps } from "@mui/material";

const MStack = ({
	direction = "row",
	spacing,
	children,
	...rest
}: StackProps) => {
	return (
		<Stack direction={direction} spacing={spacing} {...rest}>
			{children}
		</Stack>
	);
};

export default MStack;
