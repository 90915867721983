import styled from "@emotion/styled";

const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;

	label {
		margin-bottom: 6px;
	}
`;

export default InputWrapper;
