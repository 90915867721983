import { Switch, FormGroup, FormControlLabel } from "@mui/material";
import { SwitchProps } from "./interface/Switch.interface";
import { MTypography } from "components/basic";

const MSwitch = ({
	input,
	label,
	type = "text",
	meta: { touched, error },
	...custom
}: SwitchProps) => {
	return (
		<FormGroup>
			<FormControlLabel
				label={<MTypography color="textSecondary">{label}</MTypography>}
				control={<Switch {...input} />}
			/>
		</FormGroup>
	);
};

export default MSwitch;
