import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";

const api = new Api();

interface ReturnTypes {
	[x: string]: string | number | Array<object> | object;
}

const fetchTestimonials = takeLatest(
	actions.FETCH_TESTIMONIALS,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(api.get, "/testimonial");
			yield put({
				type: actions.FETCH_TESTIMONIALS_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const fetchTestimonial = takeLatest(
	actions.FETCH_TESTIMONIAL,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(api.get, `/testimonial/${action.id}`);
			yield call(action.resolve, response);
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const editTestimonial = takeLatest(
	actions.EDIT_TESTIMONIAL,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.patch,
				`/testimonial/${action.id}`,
				action.data
			);
			yield put({
				type: actions.EDIT_TESTIMONIAL_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const deleteTestimonial = takeLatest(
	actions.DELETE_TESTIMONIAL,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const responseId = yield call(
				api.delete,
				`/testimonial/${action.id}`
			);
			yield put({
				type: actions.DELETE_TESTIMONIAL_SUCCESS,
				id: responseId,
			});

			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

export default function* saga() {
	yield all([
		fetchTestimonials,
		fetchTestimonial,
		editTestimonial,
		deleteTestimonial,
	]);
}
