import { createSvgIcon } from "@mui/material/utils";

export const Envelope = createSvgIcon(
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 23 23"
		fill="currentColor"
	>
		<g clip-path="url(#clip0_58_172)">
			<path
				d="M2.07667 2.4875C1.13599 2.4875 0.372803 3.2468 0.372803 4.18267C0.372803 4.71595 0.624833 5.21743 1.05435 5.53881L8.77853 11.3024C9.1832 11.6026 9.73695 11.6026 10.1416 11.3024L12.1792 9.7838C13.0275 7.6719 15.0083 6.13565 17.3724 5.9061L17.8658 5.53881C18.2953 5.21743 18.5473 4.71595 18.5473 4.18267C18.5473 3.2468 17.7842 2.4875 16.8435 2.4875H2.07667ZM10.8232 12.2065C10.4302 12.5005 9.95177 12.6594 9.46008 12.6594C8.96838 12.6594 8.49 12.5005 8.09698 12.2065L0.372803 6.4429V13.7886C0.372803 15.0353 1.39157 16.0489 2.64462 16.0489H13.1589C12.2679 14.9753 11.7319 13.5944 11.7319 12.0935C11.7319 11.8957 11.7425 11.7015 11.7603 11.5072L10.8232 12.2065ZM23.091 12.0935C23.091 10.7447 22.5524 9.45119 21.5938 8.49747C20.6352 7.54375 19.3351 7.00796 17.9794 7.00796C16.6237 7.00796 15.3236 7.54375 14.365 8.49747C13.4063 9.45119 12.8678 10.7447 12.8678 12.0935C12.8678 13.4422 13.4063 14.7357 14.365 15.6895C15.3236 16.6432 16.6237 17.179 17.9794 17.179C19.3351 17.179 20.6352 16.6432 21.5938 15.6895C22.5524 14.7357 23.091 13.4422 23.091 12.0935ZM20.3684 10.5643C20.5884 10.7832 20.5884 11.1435 20.3684 11.3624L17.8126 13.9052C17.5925 14.1241 17.2304 14.1241 17.0103 13.9052L15.5904 12.4925C15.3704 12.2736 15.3704 11.9134 15.5904 11.6944C15.8105 11.4754 16.1726 11.4754 16.3927 11.6944L17.4114 12.708L19.5661 10.5643C19.7862 10.3453 20.1483 10.3453 20.3684 10.5643Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_58_172">
				<rect
					width="22.7182"
					height="18.0818"
					fill="white"
					transform="translate(0.372803 0.227272)"
				/>
			</clipPath>
		</defs>
	</svg>,
	"Envelope"
);
