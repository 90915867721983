import { Typography, TypographyProps } from "@mui/material";

const MTypography = ({
	children,
	variant,
	...rest
}: TypographyProps): JSX.Element => {
	return (
		<Typography variant={variant} {...rest}>
			{children}
		</Typography>
	);
};

export default MTypography;
