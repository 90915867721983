import LoadingButton from "@mui/lab/LoadingButton";
import { LoadingButtonProps } from "./interface/LoadingButton.interface";

const MLoadingButton = ({
	type = "button",
	loading = false,
	children,
	onClick,
	fullWidth = false,
	...rest
}: LoadingButtonProps) => {
	return (
		<LoadingButton
			type={type}
			loading={loading}
			onClick={onClick}
			fullWidth={fullWidth}
			{...rest}
		>
			{children}
		</LoadingButton>
	);
};

export default MLoadingButton;
