import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { TimelineProps, TimelineData } from "./interface/Timeline.interface";
import MStack from "../stack";
import MTypography from "../typography";

const MTimeline = ({ timelineContent }: TimelineProps) => {
	const renderContent = (content: any) => {
		const keys = Object.keys(content);
		return keys.map((key: string, index) => (
			<MTypography
				key={content[key]}
				variant={index === 0 ? "h6" : "inherit"}
				color={index === 0 ? "inherit" : "textSecondary"}
			>
				{content[key]}
			</MTypography>
		));
	};

	const isLastData = (index: number) => {
		return timelineContent.length === index + 1;
	};

	return (
		<Timeline>
			{timelineContent.map((content: TimelineData, index) => (
				<TimelineItem key={index}>
					<TimelineSeparator>
						<TimelineDot color="primary" />
						{!isLastData(index) && <TimelineConnector />}
					</TimelineSeparator>
					<TimelineContent>
						<MStack direction="column" spacing={1}>
							{renderContent(content)}
						</MStack>
					</TimelineContent>
				</TimelineItem>
			))}
		</Timeline>
	);
};

export default MTimeline;
