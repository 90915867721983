import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";

const api = new Api();

interface ReturnTypes {
	[x: string]: string | number | Array<object> | object;
}

const fetchTestimonialInsights = takeLatest(
	actions.FETCH_TESTIMONIAL_INSIGHTS,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.get,
				`dashboard/testimonial-insights`
			);
			yield put({
				type: actions.FETCH_TESTIMONIAL_INSIGHTS_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, response);
		} catch (error) {
			yield call(action.reject, "reject");
		}
	}
);

const fetchCustomerActivity = takeLatest(
	actions.FETCH_CUSTOMER_ACTIVITY,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(api.get, `dashboard/customer-activity`);
			yield put({
				type: actions.FETCH_CUSTOMER_ACTIVITY_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, response);
		} catch (error) {
			yield call(action.reject, "reject");
		}
	}
);

const fetchLatestTestimonials = takeLatest(
	actions.FETCH_LATEST_TESTIMONIALS,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.get,
				`dashboard/latest-testimonials/${action.count}`
			);
			yield put({
				type: actions.FETCH_LATEST_TESTIMONIALS_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, response);
		} catch (error) {
			yield call(action.reject, "reject");
		}
	}
);

const fetchDashboardOnboard = takeLatest(
	actions.FETCH_DASHOARD_ONBOARD,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(api.get, `video-tutorial/dashboard`);
			yield put({
				type: actions.FETCH_DASHOARD_ONBOARD_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, response);
		} catch (error) {
			yield call(action.reject, "reject");
		}
	}
);

export default function* saga() {
	yield all([
		fetchTestimonialInsights,
		fetchCustomerActivity,
		fetchLatestTestimonials,
		fetchDashboardOnboard,
	]);
}
