import { useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { EditorProps } from "./interface/Editor.interface";
import { MTypography } from "components";

const TextEditor = ({
	input,
	meta: { touched, error },
	getEditorInstance,
	disabled = false,
	inline = false,
	toolbar = false,
}: EditorProps) => {
	const editorRef = useRef<any>();

	useEffect(() => {
		getEditorInstance(editorRef);
		editorRef?.current?.mode.set("readonly");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editorRef]);

	return (
		<>
			<Editor
				onInit={(_, editor) => (editorRef.current = editor)}
				onEditorChange={input.onChange}
				value={input.value}
				disabled={disabled}
				init={{
					min_height: 250,
					menubar: false,
					plugins: "lists advlist autoresize",
					toolbar:
						"formatselect | " +
						"bold italic| " +
						"fontsize |" +
						"alignleft aligncenter  alignright alignjustify | outdent indent | " +
						"bullist |" +
						"removeformat",
					content_style:
						"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
					advlist_bullet_styles: "square",
					font_size_formats:
						"8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
				}}
			/>
			{touched && error && (
				<MTypography
					sx={{
						marginLeft: "15px",
					}}
					color="error"
					variant="caption"
				>
					{error}
				</MTypography>
			)}
		</>
	);
};

export default TextEditor;
