import actions from "./actions";

interface InitialState {
	loading: boolean;
}

const initialState: InitialState = {
	loading: false,
};

const reducer = (
	state: InitialState = initialState,
	action: Record<string, string | number>
) => {
	switch (action.type) {
		case actions.REGISTER:
			return { ...state, loading: true };

		case actions.REGISTER_SUCCESS:
			return { ...state, loading: false };

		case actions.REGISTER_FAILED:
			return { ...state, loading: false };

		case actions.SETBASICINFO:
			return { ...state, loading: true };

		case actions.SETBASICINFO_SUCCESS:
			return { ...state, loading: false };

		case actions.SETBASICINFO_FAILED:
			return { ...state, loading: false };

		case actions.FORGETPASSWORD:
			return { ...state, loading: true };

		case actions.FORGETPASSWORD_SUCCESS:
			return { ...state, loading: false };

		case actions.FORGETPASSWORD_FAILED:
			return { ...state, loading: false };

		case actions.FORGETPASSWORD_CHANGE:
			return { ...state, loading: true };

		case actions.FORGETPASSWORD_CHANGE_SUCCESS:
			return { ...state, loading: false };

		case actions.FORGETPASSWORD_CHANGE_FAILED:
			return { ...state, loading: false };

		case actions.CHANGEPASSWORD:
			return { ...state, loading: true };

		case actions.CHANGEPASSWORD_SUCCESS:
			return { ...state, loading: false };

		case actions.CHANGEPASSWORD_FAILED:
			return { ...state, loading: false };

		default:
			return { ...state };
	}
};

export default reducer;
