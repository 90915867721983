import jwt_decode from "jwt-decode";
import { JWTToken } from "./interface/Utils.interface";

export const hasTokenExpired = (token: string | null): boolean | undefined => {
	if (token) {
		const decodedToken: JWTToken = jwt_decode(token);
		const expireTime: number = decodedToken.exp * 1000;
		const currentTime: number = Date.now();
		const isExpired: boolean = expireTime < currentTime;
		return isExpired;
	}
};

export const filterTerm = (term: string, items: any[]): any[] => {
	return items.filter(item => {
		return Object.keys(item).some(key => {
			if (typeof item[key] === "string") {
				return item[key]
					.toString()
					.toLowerCase()
					.includes(term.toLowerCase());
			}else{
				return false;
			}
		});
	});
};

export const capitalizeSentence = (sentence: string): string => {
	return sentence.replace(/(^\w{1})|(\s{1}\w{1})/g, (match: string) =>
		match.toUpperCase()
	);
};

export const permissionData = (decodedToken: any): Array<string> => {
	let permissions: Array<string> = [];
	decodedToken.user.roles.forEach((role: any) => {
		role.permissions.forEach((data: any) => {
			let TokenPermission = Object.keys(data);
			permissions.push(TokenPermission[0]);
		});
	});
	return permissions;
};
