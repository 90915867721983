import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
} from "@mui/material";
import { Generic, TableProps } from "./interface/Table.interface";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
const MTable = ({
	column,
	data,
	count,
	onPageChange = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		page: number
	) => {},
	page,
	rowsPerPage,
}: TableProps) => {
	return (
		<>
			<PerfectScrollbar>
				<Table>
					<TableHead>
						<TableRow>
							{column.map(col => (
								<TableCell key={col.key} align={col.align} width={col.width}>{col.label}</TableCell>
							))}
						</TableRow>	
						<TableRow>
							{column.map(col => (
								<TableCell sx={{backgroundColor:"white",color:"black"}} key={col.key} >{col.subheading}</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map(tData => (
							<TableRow key={tData.id}>
								{column.map((col: Generic, index) => (
									<TableCell key={index}>
										{col.render
											? col.render(tData, data)
											: tData[col.key]}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</PerfectScrollbar>
			<TablePagination
				component="div"
				count={count}
				onPageChange={onPageChange}
				page={page}
				rowsPerPage={rowsPerPage}
			/>
		</>
	);
};

export default MTable;
