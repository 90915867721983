import { TextField, TextFieldProps } from "@mui/material";
//import { TextFieldProps } from "./interface/TextField.interface";

const MTextField = ({
	fullWidth = false,
	InputProps,
	placeholder,
	children,
	variant,
	...rest
}: TextFieldProps): JSX.Element => {
	return (
		<TextField
			fullWidth={fullWidth}
			placeholder={placeholder}
			variant={variant}
			InputProps={InputProps}
			{...rest}
		>
			{children}
		</TextField>
	);
};

export default MTextField;
