import { enqueueSnackbar, VariantType } from "notistack";

const MSnackBar = (
	message: string,
	type: VariantType = "success",
	duration = 1000
) => {
	enqueueSnackbar(message, {
		variant: type,
		autoHideDuration: duration,
		anchorOrigin: {
			vertical: "top",
			horizontal: "right",
		},
	});
};

export default MSnackBar;
