import { Container, ContainerProps } from "@mui/material";

const MContainer = ({
	children,
	sx,
	disableGutters,
	fixed,
	...rest
}: ContainerProps): JSX.Element => {
	return (
		<Container
			sx={sx}
			disableGutters={disableGutters}
			fixed={fixed}
			{...rest}
		>
			{children}
		</Container>
	);
};

export default MContainer;
