import { Box, Divider, Drawer, useMediaQuery } from "@mui/material";
import { BarChart as BarChartIcon, Users as UsersIcon } from "icons";
import { MVerticalMenu } from "components";
import SettingsIcon from "@mui/icons-material/Settings";
import {
	LayersOutlined,
	LocalOfferSharp,
	VideoLibraryOutlined,
} from "@mui/icons-material";
import { JWTToken } from "utils/interface/Utils.interface";
import jwt_decode from "jwt-decode";
import { permissionData } from "utils/Helpers";

const items = [
	{
		href: "/",
		icon: <BarChartIcon fontSize="small" />,
		title: "Dashboard",
		permission: "customer:list",
	},
	{
		href: "/customers",
		icon: <UsersIcon fontSize="small" />,
		title: "Customers",
		permission: "customer:list",
	},
	{
		href: "/testimonials",
		icon: <LocalOfferSharp fontSize="small" />,
		title: "Testimonials",
		permission: "testimonial:list",
	},
	{
		href: "/testimonialGroups",
		icon: <LayersOutlined />,
		title: "Testimonial Groups",
		permission: "testimonial_group:list",
	},
	{
		href: "/settings/emailTemplate",
		icon: <SettingsIcon />,
		title: "Settings",
		permission: "customer:list",
	},
	{
		href: "/video-tutorials",
		icon: <VideoLibraryOutlined />,
		title: "Video Tutorials",
		permission: "video_tutorial:list",
	},
];

export const Sidebar = (props: any) => {
	const { open, onClose } = props;
	const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"), {
		defaultMatches: true,
		noSsr: false,
	});
	const token: string | null = localStorage.getItem("token");
	const decodedToken: JWTToken = jwt_decode(token || "");

	const isAuthorized = (permission: string): boolean => {
		return permissionData(decodedToken).some(element => {
			return permission === element;
		});
	};

	const filteredItems = items.filter(item => {
		return isAuthorized(item.permission);
	});

	const content = (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
				}}
			>
				{/* <Box sx={{ px: 3, py:0 }}> */}
				<img
					style={{
						padding: "10px 0px 10px 20px",
						minHeight: "64px",
					}}
					alt="logo"
					src="/logo.png"
					width="60%"
				/>
				{/* </Box> */}
				<Divider
					sx={{
						borderColor: "#2D3748",
						mb: 1,
					}}
				/>
				<Box sx={{ flexGrow: 1 }}>
					<MVerticalMenu items={filteredItems} />
				</Box>
			</Box>
		</>
	);

	if (lgUp) {
		return (
			<Drawer
				anchor="left"
				open
				PaperProps={{
					sx: {
						backgroundColor: "neutral.900",
						color: "#FFFFFF",
						width: 280,
					},
				}}
				variant="permanent"
			>
				{content}
			</Drawer>
		);
	}

	return (
		<Drawer
			anchor="left"
			onClose={onClose}
			open={open}
			PaperProps={{
				sx: {
					backgroundColor: "neutral.900",
					color: "#FFFFFF",
					width: 280,
				},
			}}
			sx={{ zIndex: theme => theme.zIndex.appBar + 100 }}
			variant="temporary"
		>
			{content}
		</Drawer>
	);
};

export default Sidebar;
