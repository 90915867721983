import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";

const api = new Api();

interface ReturnTypes {
	[x: string]: string | number | Array<object> | object;
}

const fetchLandingPageTemplate = takeLatest(
	actions.FETCH_LANDINGPAGETEMPLATE,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(api.get, `landing-page`, true, {
				template_id: action.data.templateId,
				customer_id: action.data.id,
				emailTemplateOrder: action.data.emailTemplateOrder,
			});
			yield put({
				type: actions.STORE_LANDINGPAGETEMPLATE,
				payload: response,
			});
			yield put({
				type: actions.FETCH_LANDINGPAGETEMPLATE_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, response);
		} catch (error) {
			yield put({ type: actions.FETCH_LANDINGPAGETEMPLATE_FAILED });
			yield call(action.reject, "reject");
		}
	}
);

export default function* saga() {
	yield all([fetchLandingPageTemplate]);
}
