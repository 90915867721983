import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { MTypography } from "components/basic";
import {
	CheckboxGroupProps,
	Option,
} from "./interface/CheckboxGroup.interface";
import { useTheme } from "@mui/material/styles";
import { Theme } from "@mui/system";

const CheckboxGroup = ({
	input,
	horizontal = false,
	label,
	options = [],
	meta: { touched, error },
	fields,
}: CheckboxGroupProps) => {
	const theme = useTheme<Theme>();
	const isError = (): boolean => {
		if (touched && error) {
			return true;
		}
		return false;
	};

	const handleChange = (event: any, option: any) => {
		if (event.target.checked) {
			fields.push(option);
		} else {
			fields.remove(fields.value.indexOf(option));
		}
	};

	return (
		<>
			<FormControl
				required
				error={isError()}
				component="fieldset"
				sx={{
					margin: 0,
					border: `1px solid ${
						isError()
							? theme.palette.error.main
							: theme.components?.MuiOutlinedInput?.styleOverrides
									?.notchedOutline.borderColor
					}`,
					width: "100%",
					borderRadius: "10px",
					marginTop: "-10px",
				}}
				variant="standard"
			>
				<FormLabel
					component="legend"
					sx={{
						marginLeft: "20px",
					}}
				>
					{label}
				</FormLabel>
				<FormGroup
					sx={{
						flexDirection: horizontal ? "row" : "column",
						paddingLeft: "20px",
					}}
				>
					{options.map((option: Option) => (
						<FormControlLabel
							key={option.key}
							control={
								<Checkbox
									checked={fields.value.includes(option.key)}
									value={option.name}
									name={option.name}
									onChange={event =>
										handleChange(event, option.key)
									}
								/>
							}
							label={option.label}
						/>
					))}
				</FormGroup>
			</FormControl>
			<MTypography
				sx={{ marginLeft: "15px" }}
				color="error"
				variant="caption"
			>
				{touched && error}
			</MTypography>
		</>
	);
};

export default CheckboxGroup;
