import { Customer } from "../interface/Customer.interface";

const actions = {
	FETCH_CUSTOMERS: "FETCH_CUSTOMERS",
	FETCH_CUSTOMERS_SUCCESS: "FETCH_CUSTOMERS_SUCCESS",
	FETCH_CUSTOMER: "FETCH_CUSTOMER",
	SAVE_CUSTOMER: "SAVE_CUSTOMER",
	SAVE_CUSTOMER_SUCCESS: "SAVE_CUSTOMER_SUCCESS",
	DELETE_CUSTOMER: "DELETE_CUSTOMER",
	DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
	FETCH_TAGS: "FETCH_TAGS",
	FETCH_TAGS_SUCCESS: "FETCH_TAGS_SUCCESS",
	FETCH_TIMEZONES: "FETCH_TIMEZONES",
	FETCH_TIMEZONES_SUCCESS: "FETCH_TIMEZONES_SUCCESS",
	FETCH_TESTIMONIAL_PROGRESS: "FETCH_TESTIMONIAL_PROGRESS",

	fetchCustomers: (
		resolve: (token: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_CUSTOMERS,
		resolve,
		reject,
	}),

	fetchCustomer: (
		id: number | "" | undefined,
		resolve: (value: Customer) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_CUSTOMER,
		id,
		resolve,
		reject,
	}),

	saveCustomer: (
		data: Customer,
		event: string,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.SAVE_CUSTOMER,
		data,
		event,
		resolve,
		reject,
	}),

	deleteCustomer: (
		id: number | "" | undefined,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.DELETE_CUSTOMER,
		id,
		resolve,
		reject,
	}),

	fetchTags: (
		resolve: (response: any) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_TAGS,
		resolve,
		reject,
	}),

	fetchTimezones: (
		resolve: (response: any) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_TIMEZONES,
		resolve,
		reject,
	}),
	fetchTestimonialProgress: (
		customer_id: number | "" | undefined,
		resolve: (response: unknown) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_TESTIMONIAL_PROGRESS,
		customer_id,
		resolve,
		reject,
	}),
};

export default actions;
