
const actions = {
	STORE_TESTIMONIALGROUP: "STORE_TESTIMONIALGROUP",
	FETCH_TESTIMONIALGROUP_FAILED: "FETCH_TESTIMONIALGROUP_FAILED",
	FETCH_TESTIMONIALGROUP: "FETCH_TESTIMONIALGROUP",
	FETCH_TESTIMONIALGROUP_SUCCESS: "FETCH_TESTIMONIALGROUP_SUCCESS",

	fetchTestimonialGroup: (
		data: number,
		resolve: (data: object) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_TESTIMONIALGROUP,
		data,
		resolve,
		reject,
	}),
};

export default actions;
