import { currentCustomerData } from "../interface/LandingPage.interface";

const actions = {
	STORE_CURRENT_CUSTOMER: "STORE_CURRENT_CUSTOMER",
	STORE_LANDINGPAGETEMPLATE: "STORE_LANDINGPAGETEMPLATE",
	FETCH_LANDINGPAGETEMPLATE_FAILED: "FETCH_LANDINGPAGETEMPLATE_FAILED",
	FETCH_LANDINGPAGETEMPLATE: "FETCH_LANDINGPAGETEMPLATE",
	FETCH_LANDINGPAGETEMPLATE_SUCCESS: "FETCH_LANDINGPAGETEMPLATE_SUCCESS",

	fetchLandingpage: (
		data: number,
		resolve: (data: object) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_LANDINGPAGETEMPLATE,
		data,
		resolve,
		reject,
	}),

	storeCurrentCustomer: (data: currentCustomerData) => ({
		type: actions.STORE_CURRENT_CUSTOMER,
		data,
	}),
};

export default actions;
