import {
	VideoTutorial,
	videoTutorialsPages,
} from "../interface/VideoTutorial.interface";

const actions = {
	FETCH_TUTORIALS: "FETCH_TUTORIALS",
	FETCH_TUTORIALS_SUCCESS: "FETCH_TUTORIALS_SUCCESS",
	FETCH_TUTORIAL: "FETCH_TUTORIAL",
	EDIT_TUTORIAL: "EDIT_TUTORIAL",
	EDIT_TUTORIAL_SUCCESS: "EDIT_TUTORIAL_SUCCESS",
	DELETE_TUTORIAL: "DELETE_TUTORIAL",
	DELETE_TUTORIAL_SUCCESS: "DELETE_TUTORIAL_SUCCESS",
	SAVE_TUTORIAL: "SAVE_TUTORIAL",
	SAVE_TUTORIAL_SUCCESS: "SAVE_TUTORIAL_SUCCESS",
	SAVE_TUTORIAL_FAILED: "SAVE_TUTORIAL_FAILED",
	FETCH_TUTORIAL_PAGES: "FETCH_TUTORIAL_PAGES",
	FETCH_TUTORIAL_PAGES_SUCCESS: "FETCH_TUTORIAL_PAGES_SUCCESS",
	FETCH_TUTORIAL_PAGES_FAILED: "FETCH_TUTORIAL_PAGES_FAILED",

	fetchVideoTutorials: (
		resolve: (token: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_TUTORIALS,
		resolve,
		reject,
	}),

	fetchVideoTutorialPages: (
		path_name: videoTutorialsPages | any,
		resolve: (token: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_TUTORIAL_PAGES,
		path_name,
		resolve,
		reject,
	}),

	fetchVideoTutorial: (
		id: number | "" | undefined,
		resolve: (value: VideoTutorial) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_TUTORIAL,
		id,
		resolve,
		reject,
	}),

	editVideoTutorials: (
		data: VideoTutorial | any,
		id: any,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.EDIT_TUTORIAL,
		data,
		id,
		resolve,
		reject,
	}),

	saveVideoTutorial: (
		data: VideoTutorial | any,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.SAVE_TUTORIAL,
		data,
		resolve,
		reject,
	}),

	deleteVideoTutorials: (
		id: number | "" | undefined,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.DELETE_TUTORIAL,
		id,
		resolve,
		reject,
	}),
};

export default actions;
