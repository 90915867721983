import { Toolbar, ToolbarProps } from "@mui/material";

const MToolbar = ({
	disableGutters = false,
	sx = {},
	children,
}: ToolbarProps) => {
	return (
		<Toolbar disableGutters={disableGutters} sx={sx}>
			{children}
		</Toolbar>
	);
};

export default MToolbar;
