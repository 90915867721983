import TestimonialGroupsSaga from "./sagas";
import TestimonialGroupsReducer from "./reducers";
import TestimonialGroupsActions from "./actions";

export const testimonialGroupsReducer = {
	testimonialGroupsReducer: TestimonialGroupsReducer,
};

export const testimonialGroupsSaga = [TestimonialGroupsSaga()];

export { TestimonialGroupsActions };
