import React from "react";
import MBackdrop from "../backdrop";
import Box from "@mui/material/Box";
import { SpinProps } from "./interface/Spin.index";

const MSpin = ({ children, spinning }: SpinProps) => {
	const cloneChild = React.Children.map(children, child => {
		const clonedChild = React.cloneElement(child, {
			style: {
				opacity: spinning ? "0.4" : "1",
			},
		});
		return clonedChild;
	});

	return (
		<Box sx={{ position: "relative" }}>
			<MBackdrop
				sx={{ position: "absolute", background: "#fff" }}
				open={spinning}
			/>
			{cloneChild}
		</Box>
	);
};

export default MSpin;
