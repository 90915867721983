import { MBackdrop } from "components";
import { Suspense } from "react";
import { Navigate } from "react-router-dom";
import { PermittedRoutesProps } from "./interface/PermittedRoutes.interface";
import jwt_decode from "jwt-decode";
import { JWTToken } from "utils/interface/Utils.interface";
import { permissionData } from "utils/Helpers";

const PermittedRoutes = ({
	permission,
	Component,
}: PermittedRoutesProps): JSX.Element => {
	const token: string | null = localStorage.getItem("token");

	if (!token) {
		return <Navigate to="/login" replace />;
	}
	const decodedToken: JWTToken = jwt_decode(token);

	const isAuthorized = (): boolean => {
		return permissionData(decodedToken).some(element => {
			return permission.includes(element);
		});
	};

	if (!isAuthorized()) {
		return <Navigate to="/" replace />;
	}

	return (
		<Suspense fallback={<MBackdrop open={true} size={100} />}>
			<Component />
		</Suspense>
	);
};

export default PermittedRoutes;
