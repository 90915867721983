import { LandingpageFormValues } from "../interface/Landingpage.interface";

const actions = {
	SAVE_LANDINGPAGE_TEMPLATE: "SAVE_LANDINGPAGE_TEMPLATE",
	SAVE_LANDINGPAGE_TEMPLATE_SUCCESS: "SAVE_LANDINGPAGE_TEMPLATE_SUCCESS",
	FETCH_TOKENS: "FETCH_TOKENS",
	FETCH_TOKENS_SUCCESS: "FETCH_TOKENS_SUCCESS",
	FETCH_LANDINGPAGE_TEMPLATES: "FETCH_LANDINGPAGE_TEMPLATES",
	FETCH_LANDINGPAGE_TEMPLATES_SUCCESS: "FETCH_LANDINGPAGE_TEMPLATES_SUCCESS",

	saveLandingpageTemplate: (
		data: LandingpageFormValues | any,
		id: number,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.SAVE_LANDINGPAGE_TEMPLATE,
		data,
		id,
		resolve,
		reject,
	}),

	fetchTokens: (
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_TOKENS,
		resolve,
		reject,
	}),

	fetchLandingpageTemplates: (
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_LANDINGPAGE_TEMPLATES,
		resolve,
		reject,
	}),
};

export default actions;
