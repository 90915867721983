import EmailSaga from "./sagas";
import EmailReducer from "./reducers";
import emailActions from "./actions";

export const emailReducer = {
	emailReducer: EmailReducer,
};

export const emailSaga = [EmailSaga()];

export { emailActions };
