import { createSvgIcon } from "@mui/material/utils";

export const OutgoingMail = createSvgIcon(
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="5 2 22 22"
		fill="currentColor"
	>
		<path
			d="M23.1499 13.2975C24.2668 13.2975 25.3072 13.5545 26.2099 13.9707V6.56546C26.2099 5.89226 25.5214 5.34146 24.6799 5.34146H6.31992C5.47842 5.34146 4.78992 5.89226 4.78992 6.56546V18.8055C4.78992 19.4787 5.47842 20.0295 6.31992 20.0295H17.4889C17.1982 19.4664 17.0299 18.8422 17.0299 18.1935C17.0299 15.4884 19.7686 13.2975 23.1499 13.2975ZM6.31992 8.00978V6.56546L15.4999 11.1555L24.6799 6.56546V8.00978L15.4999 12.5998L6.31992 8.00978Z"
			fill="white"
		/>
		<path
			d="M23.4102 14.7296L22.3239 15.5986L24.8178 17.5815H18.5601V18.8055H24.8178L22.3239 20.7884L23.4102 21.6574L27.7401 18.1935L23.4102 14.7296Z"
			fill="white"
		/>
	</svg>,
	"OutgoingMail"
);
