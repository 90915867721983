import LandingPageTemplateSaga from "./sagas";
import LandingPageTemplateReducer from "./reducers";
import landingpageTemplateActions from "./actions";

export const landingpageTemplateReducer = {
	landingpageTemplateReducer: LandingPageTemplateReducer,
};

export const landingpageTemplateSaga = [LandingPageTemplateSaga()];

export { landingpageTemplateActions };
