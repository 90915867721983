import { CircularProgress, Box } from "@mui/material";

const MSpinner = () => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				height: "200px",
				alignItems: "center",
			}}
		>
			<CircularProgress />
		</Box>
	);
};

export default MSpinner;
