import DashboardSaga from "./sagas";
import DashboardReducer from "./reducers";
import DashboardActions from "./actions";

export const dashboardsReducer = {
	dashboardsReducer: DashboardReducer,
};

export const dashboardSaga = [DashboardSaga()];

export { DashboardActions };
