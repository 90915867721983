import { EmailFormValues } from "../interface/Email.interface";

const actions = {
	
	FETCH_TOKENS: "FETCH_TOKENS",
	FETCH_TOKENS_SUCCESS: "FETCH_TOKENS_SUCCESS",
	FETCH_EMAIL_TEMPLATES: "FETCH_EMAIL_TEMPLATES",
	FETCH_EMAIL_TEMPLATES_SUCCESS: "FETCH_EMAIL_TEMPLATES_SUCCESS",
	UPDATE_EMAIL_TEMPLATES: "UPDATE_EMAIL_TEMPLATES",
	UPDATE_EMAIL_TEMPLATES_SUCCESS: "UPDATE_EMAIL_TEMPLATES_SUCCESS",

	fetchTokens: (
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_TOKENS,
		resolve,
		reject,
	}),


	updateEmailTemplates: (
		data: EmailFormValues[],
		id: number,
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.UPDATE_EMAIL_TEMPLATES,
		data,
		id,
		resolve,
		reject,
	}),

	fetchEmailTemplates: (
		resolve: (value: string) => void,
		reject: (value: string) => void
	) => ({
		type: actions.FETCH_EMAIL_TEMPLATES,
		resolve,
		reject,
	}),
};

export default actions;
