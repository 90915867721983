import actions from "./actions";
const { fromJS } = require("immutable");

const initialState = fromJS({
	loading: false,
	currentCustomer: {},
	landingPageTemplate: {},
});

const reducer = (state = initialState, action: Record<string, string>) => {
	switch (action.type) {
		case actions.FETCH_LANDINGPAGETEMPLATE:
			return state.setIn(["loading"], true);

		case actions.STORE_LANDINGPAGETEMPLATE:
			const landingpageData = action.payload;
			return state.merge({
				landingPageTemplate: landingpageData,
			});

		case actions.STORE_CURRENT_CUSTOMER:
			return state.merge({
				loading: false,
				currentCustomer: action.data,
			});

		case actions.FETCH_LANDINGPAGETEMPLATE_FAILED:
		case actions.FETCH_LANDINGPAGETEMPLATE_SUCCESS:
			return state.setIn(["loading"], false);

		default:
			return state;
	}
};

export default reducer;
