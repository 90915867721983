import { Chip, ChipProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Theme } from "@mui/system";

const MToken = ({ label, onClick }: ChipProps) => {
	const theme = useTheme<Theme>();

	return (
		<Chip
			label={label}
			onClick={onClick}
			sx={{
				m: 1,
				borderRadius: "8px",
				color: theme.palette.neutral["100"],
				backgroundColor: theme.palette.neutral["500"],
				":hover": {
					backgroundColor: theme.palette.neutral["400"],
				},
			}}
		/>
	);
};

export default MToken;
