import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, call, all, put, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import {
	ReturnTypes,
	APIResponseError,
} from "../interface/Landingpage.interface";

const api = new Api();

const saveEmailTemplate = takeLatest(
	actions.SAVE_LANDINGPAGE_TEMPLATE,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.patch,
				`/landing-page-template/${action.id}`,
				action.data
			);
			yield put({
				type: actions.SAVE_LANDINGPAGE_TEMPLATE_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, "success");
		} catch (error) {
			const err = error as APIResponseError;
			yield call(action.reject, err.response?.data.message);
		}
	}
);

const fetchTokens = takeLatest(
	actions.FETCH_TOKENS,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(api.get, "/tokens");
			yield put({
				type: actions.FETCH_TOKENS_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "error");
		}
	}
);

const fetchEmailTemplates = takeLatest(
	actions.FETCH_LANDINGPAGE_TEMPLATES,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(api.get, "/landing-page-template");
			yield put({
				type: actions.FETCH_LANDINGPAGE_TEMPLATES_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "error");
		}
	}
);

export default function* saga() {
	yield all([saveEmailTemplate, fetchTokens, fetchEmailTemplates]);
}
