import { ClickAwayListener, Paper, Popper } from "@mui/material";
import MBox from "../box";
import { useState } from "react";
import { PopperProps } from "./interface/Popper.interface";

const MPopper = ({ trigger, children, ...rest }: PopperProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? "simple-popper" : undefined;

	return (
		<>
			<MBox onClick={handleClick}>{trigger}</MBox>
			<Popper
				id={id}
				open={open}
				anchorEl={anchorEl}
				{...rest}
				disablePortal
			>
				<ClickAwayListener onClickAway={handleClose}>
					<Paper sx={{ backgroundColor: "#f5f5f5" }}>
						{children}
					</Paper>
				</ClickAwayListener>
			</Popper>
		</>
	);
};
export default MPopper;
