import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";

const api = new Api();

interface ReturnTypes {
	[x: string]: string | number | Array<object> | object;
}

const fetchCustomers = takeLatest(
	actions.FETCH_CUSTOMERS,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(api.get, "/customers");
			yield put({
				type: actions.FETCH_CUSTOMERS_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const fetchCustomer = takeLatest(
	actions.FETCH_CUSTOMER,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(api.get, `/customers/${action.id}`);
			yield call(action.resolve, response);
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const saveCustomer = takeLatest(
	actions.SAVE_CUSTOMER,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			if (action.event === "ADD") {
				const response = yield call(
					api.post,
					"/customers",
					action.data,
					null
				);
				action.data.id = response.id;
				yield put({
					type: actions.SAVE_CUSTOMER_SUCCESS,
					payload: action.data,
					event: action.event,
				});
			}

			if (action.event === "EDIT") {
				yield call(
					api.patch,
					`/customers/${action.data.id}`,
					action.data
				);
				yield put({
					type: actions.SAVE_CUSTOMER_SUCCESS,
					payload: action.data,
					event: action.event,
				});
			}
			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const deleteCustomer = takeLatest(
	actions.DELETE_CUSTOMER,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const responseId = yield call(
				api.delete,
				`/customers/${action.id}`
			);
			yield put({
				type: actions.DELETE_CUSTOMER_SUCCESS,
				id: responseId,
			});

			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const fetchTags = takeLatest(
	actions.FETCH_TAGS,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(api.get, "/tags");
			yield put({ type: actions.FETCH_TAGS_SUCCESS, payload: response });
			yield call(action.resolve, response);
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const fetchTimezones = takeLatest(
	actions.FETCH_TIMEZONES,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(api.get, "/timezones");
			yield put({
				type: actions.FETCH_TIMEZONES_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const fetchTestimonialProgress = takeLatest(
	actions.FETCH_TESTIMONIAL_PROGRESS,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.get,
				`/customer-activity/${action?.customer_id}`
			);
			yield call(action.resolve, response);
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

export default function* saga() {
	yield all([
		fetchCustomers,
		fetchCustomer,
		saveCustomer,
		deleteCustomer,
		fetchTags,
		fetchTimezones,
		fetchTestimonialProgress,
	]);
}
