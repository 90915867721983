import TestimonialsSaga from "./sagas";
import TestimonialsReducer from "./reducers";
import testimonialsActions from "./actions";

export const testimonialsReducer = {
	testimonialsReducer: TestimonialsReducer,
};

export const testimonialsSaga = [TestimonialsSaga()];

export { testimonialsActions };
