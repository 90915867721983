import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { AutocompleteProps, Option } from "./interface/Autocomplete.interface";

const filter = createFilterOptions<Option>();

const MAutocomplete = ({
	input,
	label,
	meta: { touched, error, initial },
	options = [],
	loading,
	addNew,
	...custom
}: AutocompleteProps) => {
	const isError = (): boolean => {
		if (touched && error) {
			return true;
		}

		return false;
	};

	return (
		<Autocomplete
			multiple
			freeSolo
			id="tags-outlined"
			options={options}
			loading={loading}
			defaultValue={initial}
			getOptionLabel={option => option.value}
			filterOptions={(options, params) => {
				const filtered = filter(options, params);

				const { inputValue } = params;
				const isExisting = options.some(
					option => inputValue === option.value
				);

				if (addNew && inputValue !== "" && !isExisting) {
					filtered.push({
						value: inputValue,
					});
				}

				return filtered;
			}}
			onChange={(_, value) => {
				input.onChange(value);
			}}
			renderInput={params => {
				return (
					<TextField
						{...params}
						error={isError()}
						label={label}
						helperText={touched && error}
						{...input}
						{...custom}
					/>
				);
			}}
		/>
	);
};

export default MAutocomplete;
