import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";

const api = new Api();

interface ReturnTypes {
	[x: string]: string | number | Array<object> | object;
}

const fetchVideoTutorials = takeLatest(
	actions.FETCH_TUTORIALS,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(api.get, "/video-tutorial");
			yield put({
				type: actions.FETCH_TUTORIALS_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);
const fetchVideoTutorialsPages = takeLatest(
	actions.FETCH_TUTORIAL_PAGES,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.get,
				`/video-tutorial/page/${action.path_name}`
			);
			yield put({
				type: actions.FETCH_TUTORIAL_PAGES_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, "success");
		} catch (error) {
			yield put({ type: actions.FETCH_TUTORIAL_PAGES_FAILED });
			yield call(action.reject, "failed");
		}
	}
);

const fetchVideoTutorial = takeLatest(
	actions.FETCH_TUTORIAL,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.get,
				`/video-tutorial/${action.id}`
			);
			yield call(action.resolve, response);
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const editVideoTutorial = takeLatest(
	actions.EDIT_TUTORIAL,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.patch,
				`/video-tutorial/${action.id}`,
				action.data
			);
			yield put({
				type: actions.EDIT_TUTORIAL_SUCCESS,
				payload: response,
			});
			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const deleteVideoTutorial = takeLatest(
	actions.DELETE_TUTORIAL,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const responseId = yield call(
				api.delete,
				`/video-tutorial/${action.id}`
			);
			yield put({
				type: actions.DELETE_TUTORIAL_SUCCESS,
				id: responseId,
			});

			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

const saveVideoTutorial = takeLatest(
	actions.SAVE_TUTORIAL,
	function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
		try {
			const response = yield call(
				api.post,
				"/video-tutorial",
				action.data,
				null
			);
			action.data.id = response.id;
			yield put({
				type: actions.SAVE_TUTORIAL_SUCCESS,
				payload: action.data,
				event: action.event,
			});
			yield call(action.resolve, "success");
		} catch (error) {
			yield call(action.reject, "failed");
		}
	}
);

export default function* saga() {
	yield all([
		fetchVideoTutorials,
		fetchVideoTutorial,
		editVideoTutorial,
		deleteVideoTutorial,
		saveVideoTutorial,
		fetchVideoTutorialsPages,
	]);
}
