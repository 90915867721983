import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
const { fromJS } = require("immutable");

const initialState = fromJS({
	emailTemplates: [],
	followupEmailTemplates: [],
	tokens: [],
});

const reducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case actions.FETCH_EMAIL_TEMPLATES_SUCCESS:
			return state.setIn(["emailTemplates"], action.payload);

		case actions.UPDATE_EMAIL_TEMPLATES_SUCCESS:
			return state.setIn(["emailTemplates"], action.payload);

		case actions.FETCH_TOKENS_SUCCESS:
			return state.setIn(["tokens"], action.payload);

		default:
			return state;
	}
};

export default reducer;
