import { AnyAction, CombinedState, combineReducers } from "@reduxjs/toolkit";
import { userReducer } from "pages/login/store";
import { customersReducer } from "pages/customers/store";
import { emailReducer } from "pages/settings/email/store";
import { landingpageTemplateReducer } from "pages/settings/landingPage/store";
import { registerReducer } from "pages/register/store";
import { testimonialReducer } from "pages/testimonialForm/store";
import { landingpageReducer } from "pages/landingPage/store";
import { testimonialsReducer } from "pages/testimonials/store";
import { embedReducer } from "pages/embed/store";
import { testimonialGroupsReducer } from "pages/testimonialGroups/store";
import { dashboardsReducer } from "pages/dashboard/store";
import { videoTutorialsReducer } from "pages/videoTutorial/store";

const appReducer = combineReducers({
	...userReducer,
	...customersReducer,
	...emailReducer,
	...registerReducer,
	...testimonialReducer,
	...landingpageTemplateReducer,
	...landingpageReducer,
	...testimonialsReducer,
	...embedReducer,
	...dashboardsReducer,
	...testimonialGroupsReducer,
	...videoTutorialsReducer,
});

const rootReducer = (
	state:
		| CombinedState<{
				registerReducer: { loading: boolean };
				landingpageReducer: any;
				emailReducer: any;
				customersReducer: any;
				userReducer: any;
				testimonialReducer: any;
				landingpageTemplateReducer: any;
				testimonialsReducer: any;
				embedReducer: any;
				dashboardsReducer: any;
				testimonialGroupsReducer: any;
				videoTutorialsReducer: any;
		  }>
		| undefined,
	action: AnyAction
) => {
	if (action.type === "LOGOUT") {
		return appReducer(undefined, action);
	}
	return appReducer(state, action);
};

export default rootReducer;
