import { MGrid } from "components/basic";
import React, { useEffect, useState } from "react";
import { MasonaryProps } from "./interface/Masonary.interface";

function MuiMasonary(masonaryProps: MasonaryProps) {
	const [distributedChildren, setDistributedChildren] = useState([] as any);

	useEffect(() => {
		const distributedChildrenInMasonary = distributeChildren();
		setDistributedChildren(distributedChildrenInMasonary);
	}, [masonaryProps]);

	const distributeChildren = () => {
		const newChildren: any[] = [];
		for (let i = 0; i < masonaryProps.columns; i++) {
			newChildren.push([]);
		}
		masonaryProps.children.forEach((child: any, index: number) => {
			newChildren[index % masonaryProps.columns].push(child);
		});
		return newChildren;
	};

	return (
		<MGrid container gap={masonaryProps.gap}>
			{distributedChildren?.map((child: any) => {
				return (
					<MGrid item xs={12} sm={6} md={12 / masonaryProps.columns}>
						{child.map((element: any, key: number) => {
							return (
								<MGrid item xs={12} key={key}>
									{element}
								</MGrid>
							);
						})}
					</MGrid>
				);
			})}
		</MGrid>
	);
}

export default MuiMasonary;
