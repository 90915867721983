import { SvgIcon, SvgIconProps } from "@mui/material";

const MSvgIcon = ({
	color = "inherit",
	children,
	fontSize = "medium",
	...rest
}: SvgIconProps): JSX.Element => {
	return (
		<SvgIcon color={color} fontSize={fontSize} {...rest}>
			{children}
		</SvgIcon>
	);
};

export default MSvgIcon;
