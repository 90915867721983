import MenuIcon from "@mui/icons-material/Menu";
import { User as UserIcon } from "icons";
import { NavbarWrapper } from "./index.style";
import {
	MAvatar,
	MBox,
	MIconButton,
	MPopper,
	MToolbar,
	MTypography,
} from "components";
import { ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "pages/login/store";
import { IRootState } from "store/interface/Store.interface";
import { UserData } from "pages/profile/interface/Profile.interface";

const Navbar = (props: any) => {
	const { onSidebarOpen, ...other } = props;
	//for logout
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleLogout = (): void => {
		dispatch(userActions.logout());
		localStorage.removeItem("token");
		// Write logout logic here
		navigate("/login", { replace: true });
	};
	//for logout

	const currentUser = useSelector<IRootState, UserData>(({ userReducer }) => {
		return userReducer.getIn(["currentUser"]);
	});

	return (
		<>
			<NavbarWrapper
				sx={{
					left: {
						lg: 280,
					},
					width: {
						lg: "calc(100% - 280px)",
					},
				}}
				{...other}
			>
				<MToolbar
					disableGutters
					sx={{
						minHeight: 64,
						left: 0,
						px: 2,
					}}
				>
					<MIconButton
						onClick={onSidebarOpen}
						sx={{
							display: {
								xs: "inline-flex",
								lg: "none",
							},
						}}
					>
						<MenuIcon fontSize="small" />
					</MIconButton>
					{/* <MTooltip title="Search">
						<IconButton sx={{ ml: 1 }}>
							<SearchIcon fontSize="small" />
						</IconButton>
					</MTooltip> */}
					<MBox sx={{ flexGrow: 1 }} />
					{/* <MTooltip title="Contacts">
						<IconButton sx={{ ml: 1 }}>
							<UsersIcon fontSize="small" />
						</IconButton>
					</MTooltip>
					<MTooltip title="Notifications">
						<IconButton sx={{ ml: 1 }}>
							<MBadge
								badgeContent={4}
								color="primary"
								variant="dot"
							>
								<BellIcon fontSize="small" />
							</MBadge>
						</IconButton>
					</MTooltip> */}
					<MTypography
						variant="body1"
						sx={{
							color: "text.primary",
							display: {
								xs: "none",
								lg: "inline-flex",
							},
							ml: 1,
						}}
					>
						{currentUser?.first_name + " " + currentUser?.last_name}
					</MTypography>
					<MPopper
						trigger={
							<MAvatar
								sx={{
									height: 40,
									width: 40,
									ml: 1,
								}}
							>
								{currentUser?.first_name +
									" " +
									currentUser?.last_name}
								{/* <UserCircleIcon fontSize="small" /> */}
							</MAvatar>
						}
						placement="bottom-end"
					>
						<MenuList id="menu-list-grow">
							<MenuItem onClick={() => navigate("/profile")}>
								<ListItemIcon>
									<UserIcon />
								</ListItemIcon>
								<ListItemText>Profile</ListItemText>
							</MenuItem>
							<MenuItem onClick={handleLogout}>
								<ListItemIcon>
									<LogoutIcon />
								</ListItemIcon>
								<ListItemText>Logout</ListItemText>
							</MenuItem>
						</MenuList>
					</MPopper>
				</MToolbar>
			</NavbarWrapper>
		</>
	);
};

export default Navbar;
