import actions from "./actions";
const { fromJS } = require("immutable");

const initialState = fromJS({
	loading: true,
	testimonialGroup: {},
});

const reducer = (state = initialState, action: Record<string, string>) => {
	switch (action.type) {
		case actions.FETCH_TESTIMONIALGROUP:
			return state.setIn(["loading"], true);

		case actions.STORE_TESTIMONIALGROUP:
			const testimonialGroup = action.payload;
			return state.merge({
				testimonialGroup: testimonialGroup,
			});

		case actions.FETCH_TESTIMONIALGROUP_FAILED:
		case actions.FETCH_TESTIMONIALGROUP_SUCCESS:
			return state.setIn(["loading"], false);

		default:
			return state;
	}
};

export default reducer;
