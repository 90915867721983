import EmbedSaga from "./sagas";
import EmbedReducer from "./reducers";
import EmbedActions from "./actions";

export const embedReducer = {
	embedReducer: EmbedReducer,
};

export const embedSaga = [EmbedSaga()];

export { EmbedActions };
