import { all } from "redux-saga/effects";
import { userSaga } from "pages/login/store";
import { customersSaga } from "pages/customers/store";
import { emailSaga } from "pages/settings/email/store";
import { registerSaga } from "pages/register/store";
import { testimonialSaga } from "pages/testimonialForm/store";
import { landingpageSaga } from "pages/landingPage/store";
import { landingpageTemplateSaga } from "pages/settings/landingPage/store";
import { testimonialsSaga } from "pages/testimonials/store";
import { embedSaga } from "pages/embed/store";
import { testimonialGroupsSaga } from "pages/testimonialGroups/store";
import { dashboardSaga } from "pages/dashboard/store";
import { videoTutorialsSaga } from "pages/videoTutorial/store";

export default function* rootSaga() {
	yield all([
		...userSaga,
		...customersSaga,
		...emailSaga,
		...registerSaga,
		...testimonialSaga,
		...landingpageSaga,
		...landingpageTemplateSaga,
		...testimonialsSaga,
		...embedSaga,
		...dashboardSaga,
		...testimonialGroupsSaga,
		...videoTutorialsSaga,
	]);
}
